<template src="./page.html"></template>

<script>
import Http from '@/shared/http-config'
import CmsNavRecursion from '../nav-recursion/NavRecursion';
import CmsPageSettings from "../page-settings/PageSettings";
import CmsStore from '../CmsStore'
import RegisterStoreModule from '@/shared/registerStoreModule'
import { mapFields } from 'vuex-map-fields';
import Editor from '@tinymce/tinymce-vue';
import tinymce from '@/shared/tinymce';
import draggable from 'vuedraggable'
import DisplayWebsite from '@/shared/DisplayWebsite'
import introJs from '@/shared/intro.js';
import {VueCroppieComponent} from 'vue-croppie'

export default {
  name: 'Page',
  mixins: [CmsNavRecursion, RegisterStoreModule, tinymce],
  components: {CmsPageSettings, CmsNavRecursion, 'tinymceEditor': Editor, draggable, DisplayWebsite, 'vue-croppie': VueCroppieComponent},
  created: function(){
    let self = this;
    self.registerStoreModule('cms', CmsStore);
    self.start();
    self.getTeasers();

    introJs.setOption('steps', [
      {
        element: '[data-intro-step="1"]',
        intro: "Du befindest dich hier im Bearbeitungsbereich deiner Website. Zuerst erklären wir die sich wiederholende Struktur im oberen Bereich.",
      },
      {
        element: '[data-intro-step="2"]',
        intro: "Zum Bearbeiten der Inhalte unterschiedlicher Seiten, kannst du hier einen anderen Bereich auswählen.",
      },
      {
        element: '[data-intro-step="3"]',
        intro: "Bei Klick auf &quot;Vorschau&quot; wechselst du zur Live-Vorschau deiner Website.",
      },
      {
        element: '[data-intro-step="4"]',
        intro: "Diese Navigationsleiste spiegelt den Aufbau deiner Website wider. Du findest hier alle Menüpunkte. Graue Menüpunkte sind bis auf das Header-Bild nicht anpassbar, während Inhalte unter schwarzen Punkten bearbeitbar sind. Ein roter Button nach dem Menüpunkt bedeutet, dass die Seite inaktiv ist. Ein orangener Button bedeutet, dass die Seite aktiv, aber der Menüpunkt nicht sichtbar ist.",
      },
      {
        element: '[data-intro-step="5"]',
        intro: "Kommen wir nun zum Inhaltsbereich. Hier kannst du das Header-Bild oder die Header-Bilder der jeweiligen Seite hinzufügen und ändern. Sind mehrere Bilder ausgewählt, siehst du anhand von Kreisen, wie viele Bilder gewählt wurden. Mehrere Bilder werden nacheinander durchlaufen.",
      },
      {
        element: '[data-intro-step="7"]',
        intro: "Zum Bearbeiten klickst du auf den Stift. Du wirst dann durch die nächsten Schritte geführt.",
        disableInteraction: true
      },
      {
        element: '[data-intro-step="8"]',
        intro: "Durch Klick auf das Auge kannst du den Header aktivieren oder deaktivieren. Die gewählte Seite hat dann kein Bild im Header.",
        disableInteraction: true
      },
      {
        element: '[data-intro-step="6"]',
        intro: "Mit Klick auf &quot;Aktionsteaser&quot; kannst du einstellen, ob im Teaser auf der Website ein Button angezeigt werden soll. Klicke jetzt auf &quot;Aktionsteaser&quot;",
      },
      {
        element: '[data-intro-step="9"]',
        intro: "Ist der Button aktiv, öffnen sich weitere Einstellungen. Hier kann der Titel, Text und Link für den Teaser eingestellt werden.",
      },
      {
        element: '[data-intro-step="10"]',
        intro: "Unter &quot;Teaser auswählen&quot; siehst du, welcher Teaser derzeit ausgewählt ist oder kannst einen anderen Teaser auswählen. Einen neuen Teaser erstellst du, indem du unter &quot;Teaser auswählen&quot; den letzten Punkt &quot;Neuen Teaser erstellen&quot; anklickst.",
      },
      {
        element: '[data-intro-step="11"]',
        intro: "Ist ein Teaser gewählt, sind die Felder mit den gespeicherten Inhalten ausgefüllt. Ist kein bestehender Teaser ausgewählt, müssen alle Felder ausgefüllt und mit „Teaser-Änderungen speichern“ gesichert werden.",
      },
      {
        element: '[data-intro-step="12"]',
        intro: "Fährst du mit der Maus über schwarze Menüpunkte, findest du am Ende der Leiste die Möglichkeit, neue Unterpunkte zu der Seite hinzuzufügen. Nach Klick auf „Neuer Menüpunkt“ öffnet sich eine neue, leere Seite. Den Namen der Seite vergibst du in den Seiteneinstellungen – Lege jetzt einen neuen Menüpunkt an.",
      },
      {
        intro: 'Hier kannst du nun die Inhalte deines neuen Menüpunktes bearbeiten.'
      },
      {
        element: '[data-intro-step="13"]',
        intro: "Neben dem Header und Menü, kann auch der Inhalt von einigen Seiten frei bearbeitet werden. Hier ist eine Vorschau des bisherigen Inhalts zu sehen.",
      },
      {
        element: '[data-intro-step="14"]',
        intro: "Durch Klick auf den Stift wechselst du in den Bearbeitungsmodus.",
      },
      {
        element: '[data-intro-step="15"]',
        intro: "Zuletzt erklären wir die Seiteneinstellungen. Diese sowie die Meta-Informationen können hier geändert werden.",
      },
      {
        element: '[data-intro-step="16"]',
        intro: "Hier kannst du den Titel der Seite (Menüpunkt) sowie den Link-Namen und die Ebene ändern.",
      },
      {
        element: '[data-intro-step="17"]',
        intro: "Einstellungen zur Sichtbarkeit der Seite sind hier möglich. Hier kann auch eingestellt werden, ob die Seite ein externer Link ist.",
      },
      {
        element: '[data-intro-step="18"]',
        intro: "Per Drag & Drop kannst du hier die Reihenfolge ändern.",
      },
      {
        intro: "Herzlichen Glückwunsch! Du hast den Wizard zur Internetseite erfolgreich durchlaufen. Bearbeite nun deine Webseite oder kontaktiere uns bei weiteren Fragen.",
      },
    ]);

    introJs.onbeforechange(function(targetElement) {
      if(introJs._currentStep === 0){
        self.show_teaser = false;
      }
      if(introJs._currentStep === 12){
        self.show_editor = false;
        if(!self.$route.params.parent_id){
          return false;
        }
      }
      if(introJs._currentStep === 8 && !self.show_teaser && self.current_header.length > 0 && !self.header_deactivated && self.current_nav.start === true){
        return false;
      }
    });

    introJs.firstLoad('website_cms');
  },
  watch: {
    $route(to, from) {
      this.start();
    }
  },
  mounted: function () {
    let self = this;
    self.editorOptions = {
      plugins: 'searchreplace autolink fullscreen image link media table lists imagetools code',
      height: 600,
      menubar: false,
      toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | code fullscreen | removeformat | image media link | modul-select | form-select | code',
      language: 'de',
      content_css: (self.template !== null ? self.template.css.join(',') : ''),
      language_url: 'https://static.fahrschul-system.de/tinymce/langs/de.js',
      default_link_target: "_blank",
      file_picker_callback: tinymce.methods.filePicker,
      automatic_uploads: true,
      images_upload_handler: tinymce.methods.upload,
      imagetools_toolbar: 'editimage imageoptions',
      setup: function (editor) {
        editor.ui.registry.addSplitButton('modul-select', {
          text: 'Modul Auswahl',
          onAction: function () {
          },
          onItemAction: function (api, value) {
            editor.insertContent(value);
          },
          fetch: function (callback) {
            let items = [];
            self.modules.forEach(function (item) {
              items.push({
                type: 'choiceitem',
                text: item.title,
                value: item.alias
              })
            });
            callback(items);
          }
        });
        editor.ui.registry.addSplitButton('form-select', {
          text: 'Formular Auswahl',
          onAction: function () {
          },
          onItemAction: function (api, value) {
            editor.insertContent(value);
          },
          fetch: function (callback) {
            let items = [];
            self.forms.forEach(function (item) {
              items.push({
                type: 'choiceitem',
                text: item.title,
                value: item.alias
              })
            });
            callback(items);
          }
        });
      }
    }
  },
  data: function () {
    return {
      introJs: introJs,
      file: null,
      file_error: false,
      imgPreviewUrl: null,
      header: {
        new_file: null,
        title: '',
        copyright: '',
        online: 1,
      },
      packets: [],
      sidebarNav: [],
      header_slide: 0,
      show_editor: false,
      show_teaser: false,
      editorOptions: {},
      current_header: [],
      teasers: [],
      display_website: [
        {
          value: 1,
          name: 'Auto',
          icon: 'https://files.fahrschul-system.de/1_0/klasseb.png'
        },
        {
          value: 2,
          name: 'Motorrad',
          icon: 'https://files.fahrschul-system.de/1_0/klassea.png'
        },
        {
          value: 4,
          name: 'BKF',
          icon: 'https://files.fahrschul-system.de/1_0/klassec.png'
        },
      ],
    }
  },
  computed: {
    ...mapFields('cms', [
      'template',
      'templates',
      'headers',
      'language',
      'languages',
      'modules',
      'forms',
      'navigation',
      'current_nav',
      'current_nav.id',
      'current_nav.content',
      'current_nav.header_deactivated',
    ]),
    header_visible_button_variant: function () {
      if(this.header_deactivated === true){
        return 'outline-primary';
      }
      return 'primary';
    },
    html_content: function () {
      if(typeof(this.current_nav.content) !== 'undefined'){
        let html = this.current_nav.content;
        this.modules.forEach(function (modul) {
          html = html.replace(modul.alias, '<a target="_new" href="' + modul.link + '">' + modul.alias + '</a>');
        });
        return html;
      }
    },
  },
  methods: {
    start: function(){
      let self = this;
      Http.request('get', '/website/navigation/list/' + self.$route.params.language)
        .then((navigation) => {
          self.navigation = navigation.data;

          let sidebarNav = [{
            _name: 'CSidebarNav',
            _children: self.navRecursionSidebar(self.navigation, 0)
          }];
          self.sidebarNav = sidebarNav;

          let id = self.$route.params.id;
          if(self.$route.params.parent_id){
            self.addPage(self.$route.params.parent_id);
          }
          else if (id.replace("_cat", "") > 0) {
            self.loadPage(id.replace("_cat", ""));
          }
          else if(self.navigation.length > 0){
            self.$router.replace('/website/cms/' + self.$route.params.template + '/' + self.$route.params.language + '/' + self.navigation[0].id);
          }
        })
    },
    addPage: function(parent_id){
      this.current_nav = {
        id: null,
        title: "",
        alias: "",
        parent_id: parent_id,
        owned: true,
        content: "",
        header_deactivated: false,
        is_external_link: 0,
        language_id: this.$route.params.language,
        online: 1,
        module_id: 0,
        show_link: 1,
        start: false,
        allow_user_navTitle: false,
        allow_user_content: false,
        allow_user_online: false
      };
      this.$refs.cmsSettings.meta_tags = [];
      this.$refs.cmsSettings.order = this.navRecursion([], this.navigation, 0, this.current_nav.parent_id);
      if(this.$refs.cmsSettings.order.findIndex(nav => nav.value === this.current_nav.id) === -1) {
        this.$refs.cmsSettings.order.push({
          text: 'Neuer Menüpunkt',
          owned: true
        });
      }

      this.show_editor = true;
      if(typeof(this.$refs.cmsSettings.$refs.title) !== 'undefined'){
        this.$refs.cmsSettings.$refs.title.focus();
      }
    },
    loadPage: function (id) {
      let self = this;
      self.show_editor = false;

      Http.request('get', '/website/navigation/' + id)
        .then((content) => {
          self.current_nav = content.data;
          self.$refs.cmsSettings.order = self.navRecursion([], self.navigation, 0, self.current_nav.parent_id);

          Http.request('get', '/website/navigation/' + id + '/header/packets')
            .then((packets) => {
              self.packets = packets.data;
            });

          Http.request('get', '/website/navigation/' + self.current_nav.id + '/meta')
            .then((meta_tags) => {
              self.$refs.cmsSettings.meta_tags = meta_tags.data;
            });

          Http.request('get', '/website/navigation/' + self.current_nav.id + '/header')
            .then((headers) => {
              self.current_header = headers.data;
              self.current_header.forEach(function (header) {
                // push header object to complete header list for preselecting checkboxes
                self.$store.dispatch('cms/replaceHeader', JSON.parse(JSON.stringify(header)));
              });
            });
        });
    },
    savePage: function () {
      let self = this;

      if(self.current_nav.content === ''){
        self.show_editor = true;
        self.$bvToast.toast('Bitte gib ein Inhalt ein', {
          title: 'Pflichtfelder',
          variant: 'danger',
          autoHideDelay: 5000
        })
      }
      else if(self.current_nav.owned === false){
        let data = {};
        if(self.current_nav.allow_user_content === true){
          data.content = self.current_nav.content;
        }
        if(self.current_nav.allow_user_online === true){
          data.online = self.current_nav.online;
        }
        if(self.current_nav.allow_user_navTitle === true){
          data.navTitle = self.current_nav.title;
        }

        Http.request('POST', '/website/navigation/' + self.current_nav.id + '/custom', data).then(function () {
          self.show_editor = false;

          self.$bvToast.toast('Gespeichert', {
            title: 'Die Änderungen wurden gespeichert',
            variant: 'success',
            autoHideDelay: 5000
          })
        });
      }
      else {
        let data = JSON.parse(JSON.stringify(self.current_nav));
        delete data.header;

        data.order = self.$refs.cmsSettings.order;
        data.meta_tags = self.$refs.cmsSettings.meta_tags;

        Http.request('POST', '/website/navigation/' + data.id, data).then(function (response) {
          self.show_editor = false;

          let notice = '';
          if(self.current_nav.show_link === 1 && self.current_nav.online === 1){
            notice = "Der Menüpunkt ist aktiv, und für Kunden sichtbar";
          }
          else if(self.current_nav.online === 1){
            notice = "Der Menüpunkt ist aktiv, aber nicht für Kunden sichtbar";
          }
          else {
            notice = 'Der Menüpunkt ist noch deaktiviert';
          }

          self.$bvToast.toast(notice, {
            title: 'Gespeichert',
            variant: 'success',
            autoHideDelay: 5000
          });

          self.$router.replace('/website/cms/' + self.$route.params.template + '/' + self.$route.params.language + '/' + response.data.id);
        });
      }
    },
    deletePage(){
      let self = this;
      this.$bvModal.msgBoxConfirm('Möchtest du diesen Menüpunkt wirklich löschen?', {
          headerBgVariant: 'primary',
          title: 'Menüpunkt löschen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            Http.request('DELETE', '/website/navigation/' + this.current_nav.id).then(function () {
              self.current_nav = {};
              self.show_editor = false;
              self.$router.replace('/website/cms/' + self.$route.params.template + '/' + self.$route.params.language + '/0');
            });
          }
        });
    },
    deleteHeader(header, key){
      let self = this;
      this.$bvModal.msgBoxConfirm('Möchtest du diesen Header wirklich (von allen Seiten) löschen?', {
          headerBgVariant: 'primary',
          title: 'Headerbild löschen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            Http.request('DELETE', '/website/header/' + self.$route.params.template + '/' + header.id).then(function () {
              self.current_header.splice(self.current_header.findIndex(f => f.id === header.id), 1);
              self.$store.dispatch('cms/deleteHeader', key);
            });
          }
        });
    },
    toggleHeaderDeactivated: function () {
      let self = this;
      let header_deactivated = !this.header_deactivated;
      Http.request('POST', '/website/navigation/' + self.current_nav.id + '/header/deactivate/', {mode: header_deactivated}).then(function () {
        self.header_deactivated = header_deactivated;
      });
    },
    setTeaserDisplayWebsite (value){
      this.current_header[this.header_slide].button.display_website = value;
    },
    getTeasers: function(){
      let self = this;
      Http.request('get', '/website/teaser').then((teasers) => {
        self.teasers = [];
        self.teasers.push({
          value: { id: -1, title: "", text: "", link: "", display_website: 7 },
          text: '- Standard (GECCO)'
        });
        teasers.data.forEach(function (teaser) {
          self.teasers.push({
            value: teaser,
            text: teaser.title
          });
        });
        self.teasers.push({
          value: { id: 0, title: "", text: "", link: "", display_website: 7 },
          text: '+ Neuer Teaser'
        });
      })
    },
    saveTeaser: function () {
      let self = this;
      let button = self.current_header[self.header_slide].button;
      if(self.languages.length <= 1){
        button.display_website = 1;
      }

      if (button.id === -1) {
        Http.request('POST', '/website/teaser/allocate/' + self.current_header[self.header_slide].id + '/0');
      }
      else {
        Http.request('POST', '/website/teaser/' + button.id, button).then(function (teaser) {
          Http.request('POST', '/website/teaser/allocate/' + self.current_header[self.header_slide].id + '/' + teaser.data.id);
          self.loadPage(self.current_nav.id);
          self.getTeasers();
        });
      }
    },
    deleteTeaser: function () {
      let self = this;
      this.$bvModal.msgBoxConfirm('Möchtest du diesen Teaser wirklich löschen?', {
          headerBgVariant: 'primary',
          title: 'Teaser löschen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(value => {
          if(value === true){
            Http.request('DELETE', '/website/teaser/' + self.current_header[self.header_slide].button.id).then(function () {
              self.loadPage(self.current_nav.id);
              self.getTeasers();
            });
          }
        });
    },
    onFileChange(e) {
      let self = this;
      let file = e.target.files[0];
      let imgPreviewUrl = URL.createObjectURL(file);
      let img = new Image();
      img.src = imgPreviewUrl;
      img.onload = function() {
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        if (
          width >= self.template.header_width &&
          height >= self.template.header_height
        ) {
          self.imgPreviewUrl = imgPreviewUrl;
          self.$refs['header-crop-modal'].show();
        }
        else {
          self.file = null;
          self.file_error = true;
        }
      };
      return img;
    },
    setCropImage(){
      this.$refs.croppieRef.bind({
        url: this.imgPreviewUrl,
        zoom: 0
      });
    },
    resetHeader: function(){
      let self = this;
      self.$refs['header-modal'].hide();
      self.current_header = [];
      self.saveHeaderSort().then(function () {
        Http.request('get', '/website/navigation/' + self.id + '/header')
          .then((headers) => {
            self.current_header = headers.data;
            self.current_header.forEach(function (header) {
              // push header object to complete header list for preselecting checkboxes
              self.$store.dispatch('cms/replaceHeader', JSON.parse(JSON.stringify(header)));
            });
          });
      });
    },
    saveHeaderSort: function(){
      let data = {};
      data.header = [];
      this.current_header.forEach(function (header) {
        data.header.push({
          id: header.id
        });
      });
      this.$refs['header-sort-modal'].hide();
      return Http.request('POST', '/website/navigation/' + this.id + '/header', data);
    },
    /* Todo: find solution for croppieRef in unit test */
    /* istanbul ignore next */
    saveNewHeader () {
      const isValid = this.header.title.length > 0 && this.header.copyright.length > 0;
      if (isValid) {
        let self = this;
        self.$refs['header-crop-modal'].hide();
        let options = {
          format: 'jpeg',
          type: 'blob',
          size: {
            width: self.template.header_width,
            height: self.template.header_height
          }
        };

        self.$refs.croppieRef.result(options, (output) => {
          self.header.new_file = output;

          let formData = new FormData();
          formData.append('new_file', self.header.new_file);
          formData.append('title', self.header.title);
          formData.append('copyright', self.header.copyright);
          formData.append('online', self.header.online);

          Http.request('POST', '/website/header/' + self.template.id, formData).then(function (header) {
            self.$store.dispatch('cms/addHeader', JSON.parse(JSON.stringify(header.data)));
            self.current_header.push(header.data);
            self.$refs['header-sort-modal'].show();
          });
        });
      }
    },
    /* Todo: find solution for croppieRef in unit test */
    /* istanbul ignore next */
    rotate(rotationAngle) {
      this.$refs.croppieRef.rotate(rotationAngle);
    }
  },
}
</script>

<style>
.carousel-item .carousel-caption {
  color: inherit;
}
</style>
